<template>
<div>
    <vx-card class="mt-8">
         <div class="card-title">
            <h2 class="w-auto my-2">{{ LabelConstant.headingLabelExcludedIPAddresses }}</h2>
            <vs-button :title="LabelConstant.buttonLabelAddNewExcludedIP" @click="createIpAddress=true, popupTitle = LabelConstant.popupTitleCreateIPAddressExclusion,popupButton=LabelConstant.buttonLabelCreateExcludedIPAddress" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn bg-actionbutton">{{ LabelConstant.buttonLabelAddNewExcludedIP }}</vs-button>
        </div>
        <div class="common-table">      
             <!--Table Select Row-->
            <div class="table-row-select">
                <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recordList" />
                </vs-select>
            </div>
            <vs-table :data="excludediplist" ref="tableData" search :max-items="perPage" pagination>
                <template slot="thead">
                    <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                    <vs-th>{{ LabelConstant.tableHeaderExcludedIPAddresses }}</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <vs-button title="Edit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left" color="primary" @click="editExcludeip(tr.Excluded_IP), popupTitle = 'Edit IP Address Exclusion', popupButton=LabelConstant.buttonLabelUpdateExcludedIPAddress"></vs-button>
                            <vs-button title="Delete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0" @click="deleteIp(tr)"></vs-button>
                        </vs-td>
                        <vs-td :data="tr.Excluded_IP">{{tr.Excluded_IP}}</vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{excludediplist.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{excludediplist.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries</p>                    
        </div>       
        
    </vx-card>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>{{ LabelConstant.popupHeadingLabelDeleteExcludedIP }}</h4>
                    <span class="deleted-no">{{ ipaddress?ipaddress:''}}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="deleteIpaddress()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button @click="deletepopupActive=false,ipaddress=null" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
    <!-- Forword Email -->
    <vs-popup class="tag-info" :title="popupTitle" :active.sync="createIpAddress">
        <form @submit.prevent="checkValidation()" autocomplete="off" data-vv-scope="excludeip">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col input-text w-full">
                        <div class="popup-icon">
                            <img src="../../../src/assets/images/ip.png" />
                        </div>

                        <vs-input type="text" class="w-full" label-placeholder="IP Address" v-model="ipaddress" name="ipaddress" v-validate="'required|ip'" />
                        <span class="text-danger text-sm">{{ errors.first('excludeip.ipaddress') }}</span>
                        <span class="text-danger error-msg" v-if="excludeIPServerSideError">{{ excludeIPServerSideError }}</span>
                        <p>{{ LabelConstant.inputLabelInfoExcludeIp }}</p>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8 sm:w-auto w-full">
                <vs-button type="filled" button="submit" color="primary" class="sm:w-auto w-full">{{ popupButton }}</vs-button>
                <vs-button @click="cancelExcludeIp()" color="grey" type="border" class="sm:ml-4 ml-0 sm:mt-2 mt-2 grey-btn sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </form>
    </vs-popup>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from "vue-select";
import HelpModal from "../../components/modal/HelpModal";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        ipaddress: {
            required: "Please enter Ip address.",
            ip: "Please enter valid ip."
        }
    }
};
Validator.localize("en", dict);

export default {
    components: {
        VxCard,
        DateRangePicker,
        vSelect,
        HelpModal
    },
    data() {
        return {
            /* Popup */
            popupActive: false,
            excludeIPServerSideError: null,
            popupTitle: null,
            popupButton: null,
            deletepopupActive: false,
            createIpAddress: false,
            ipaddress: null,
            pid: null,
            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "100",
                    value: 100
                }
            ],
            excludediplist: []
        };
    },
    mounted() {
        this.getExcludeList();
    },
    methods: {
        /* Close Popup */
        closepopup() {
            (this.popupActive = false), (this.detailspopupActive = false);
        },
        async getExcludeList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigExcludedIP/GetExcludedIPList")
                .then(response => {
                    let data = response.data;
                    this.excludediplist = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // check validation
        async checkValidation() {
            this.$validator.validateAll("excludeip").then(async result => {
                if (result) {
                    this.createExcludeIp();
                }
            });
        },
        // edit exclude ip 
        async editExcludeip(id) {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigExcludedIP/GetExcludedIP?id=" + id)
                .then(response => {
                    let data = response.data;
                    this.ipaddress = data.Excluded_IP;
                    this.pid = data.Excluded_IP;
                    this.createIpAddress = true;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // open popup for confirmation
        async deleteIp(data) {
            this.ipaddress = data.Excluded_IP;
            this.deletepopupActive = true;
        },
        // call api to delete ip
        async deleteIpaddress() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigExcludedIP/DeleteExcludedIP?id=" + this.ipaddress)
                .then(() => {
                    this.ipaddress = null;
                    this.deletepopupActive = false;
                    this.getExcludeList();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // create or update exclude ip and call it.
        async createExcludeIp() {
            this.$vs.loading();
            let input;
            if (this.pid) {
                input = {
                    Excluded_IP: this.ipaddress,
                    Previous_IP: this.pid
                };
            } else {
                input = {
                    Excluded_IP: this.ipaddress
                };
            }
            this.excludeIPServerSideError = null;
            let url = this.pid ?
                "/ws/ConfigExcludedIP/UpdateExcludedIP?id=" + this.ipaddress :
                "/ws/ConfigExcludedIP/CreateExcludedIP";
            await this.axios
                .post(url, input)
                .then(() => {
                    this.ipaddress = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.pid = null;
                    this.createIpAddress = false;
                    this.getExcludeList();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.excludeIPServerSideError = e.response.data.Errors[0].Message
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        cancelExcludeIp(){
            this.createIpAddress=false;
            this.ipaddress=null;
            setTimeout(() => {
                this.errors.clear();
            }, 100);
        }
    },
    computed: {
        isTableRecordEntriesDisplay() {
            if (this.excludediplist.length > 0) {
                if (this.$refs.tableData.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearch: function () {
            if (this.excludediplist.length > 0) {
                if (
                    this.$refs.tableData.searchx == null ||
                    this.$refs.tableData.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        showingDataFrom: function () {
            if (this.excludediplist.length > 0) {
                return (this.$refs.tableData.currentx - 1) * this.perPage + 1;
            }
        },
        showingDataTo: function () {
            if (this.excludediplist.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPages;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        },
        totalSearchedData: function () {
            if (this.excludediplist.length > 0) {
                return this.$refs.tableData.queriedResults.length;
            }
        },
        showingSearchedDataTo: function () {
            if (this.excludediplist.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPagesSearch;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        }
    }
};
</script>
